import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import { getAllDevices } from 'api/devices';
import { deleteDevice } from 'api/devices';
import AddDeviceForm from './AddDeviceForm';
import InfoIcon from '@mui/icons-material/Info';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';
import { Paper }  from '@mui/material';
import { Typography } from '@mui/material';

interface VideoData {
  video_id: string;
  uploaded_at: string;
  indexed_at: string;
  status: string;
  frames_count: number;
  video_time: string;
  framerate: number;
}

const DevicesList: React.FC = () => {
  const [data, setData] = useState<VideoData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteVideoId, setDeleteVideoId] = useState('');
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
  const [isAddDeviceDialogOpen, setIsAddDeviceDialogOpen] = useState(false);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await getAllDevices();
    //   const updatedData = response.map((device: object, index: number) => ({
    //     ...device,
    //     id: index + 1,
    //   }));
      setData(response);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching video metadata:', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDelete = async (device_id: string) => {
    const confirmed = window.confirm(`Are you sure you want to delete ${device_id}?`);
    if (confirmed) {
      setIsLoading(true);
      setDeleteVideoId(device_id);
      setIsDeleteConfirmationOpen(true);
      try {
        // Perform delete operation
        const result = await deleteDevice(device_id);
        fetchData();
      } catch (error) {
        console.error('Error deleting device:', error);
      } finally {
        setIsLoading(false);
        setIsDeleteConfirmationOpen(false);
      }
    }
  };

  const renderDeleteButton = (params: GridRenderCellParams) => (
    <Button
      variant="contained"
      color="error"
      startIcon={<DeleteIcon />}
      onClick={() => handleDelete(params.row.id as string)}
      disabled={isLoading}
    >
      Удалить
    </Button>
  );

  const renderStatusIcon = (params: GridRenderCellParams) => {
    const status = params.row.is_active as boolean;
    if (status === true) {
      return <CheckCircleIcon style={{ marginTop: '15px', color: 'green' }} />;
    }
    else{
        return <ErrorIcon style={{ marginTop: '15px', color:"red" }} />
    }
  };

  const renderAnalyticsButton = (params: GridRenderCellParams) => {
    const id = params.row.id;
      return (
        <Button
          variant="contained"
          color="secondary"
          startIcon={<InfoIcon />}
          component={Link}
          to={`/device-data?device_id=${id}`}
          disabled={isLoading}
        >
          Аналитика
        </Button>
      )
    }


  const openAddDeviceDialog = () => {
    setIsAddDeviceDialogOpen(true);
  };

  const closeAddDeviceDialog = () => {
    setIsAddDeviceDialogOpen(false);
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'Device ID', width: 300 },
    { field: 'device_family_name', headerName: 'Имя семейства', width: 120 },
    { field: 'device_owner_id', headerName: 'ID владельца', width: 120 },
    { field: 'adding_time', headerName: 'Дата добавления', width: 230 },
    { field: 'last_request_time', headerName: 'Последний запрос', width: 230 },
    { field: 'activation_time', headerName: 'Дата активации', width: 230 },
    // { field: 'is_active', headerName: 'Активно', width: 200 },
    { field: 'device_api_key', headerName: 'API ключ', width: 120 },
    { field: 'device_activation_key', headerName: 'Ключ активации', width: 120 },
    { field: 'description', headerName: 'Описание', width: 120 },
    {
      field: 'status_icon',
      headerName: 'Активно',
      width: 100,
      renderCell: renderStatusIcon,
    },
    {
      field: 'analytics',
      headerName: 'Аналитика',
      width: 160,
      renderCell: renderAnalyticsButton,
    },
    {
      field: 'delete',
      headerName: 'Удалить',
      width: 140,
      renderCell: renderDeleteButton,
    }
  ];

  return (
    
    <Box style={{ height: 800, width: '100%' }}>
      <Paper sx={{ padding: 3, display: 'flex', alignItems: 'center', marginBottom: 3 }} elevation={5}>
      <Button variant="contained" color="primary" onClick={openAddDeviceDialog}>
          Добавить устройство
      </Button>
      </Paper>
    <Paper sx={{ padding: 3, marginBottom: 3 }} elevation={5}>
      <Typography variant='h4' sx={{marginBottom: 3}}>Список устройств</Typography>
    <DataGrid rows={data} columns={columns} initialState={{
        sorting: {
        sortModel: [{ field: 'uploaded_at', sort: 'desc' }],
        },
      }}/>
      </Paper>


    <Dialog open={isAddDeviceDialogOpen} onClose={closeAddDeviceDialog}>
        <DialogContent>
          <AddDeviceForm onSuccess={fetchData} />
        </DialogContent>
      </Dialog>

      <Dialog open={isDeleteConfirmationOpen} onClose={() => setIsDeleteConfirmationOpen(false)}>
        <DialogContent>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <CircularProgress size={20} style={{ marginRight: '10px' }} />
            <span>Удаление устройства {deleteVideoId}...</span>
          </div>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default DevicesList;