import React, { useState, FormEvent } from 'react';
import { TextField, Button, Container, Typography } from '@mui/material';
import { addContractor } from 'api/contractors_management';

interface AddContractorFormProps {
    onSuccess: () => void;
  }

const AddContractorForm: React.FC<AddContractorFormProps> = ( {onSuccess} ) => {
    const [username, setUsername] = useState<string>('');
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<string | null>(null);

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setError(null);
        setSuccess(null);

        try {
            const response = await addContractor(username, firstName, lastName, password, email);
            setSuccess('Contractor added successfully!');
            onSuccess();
            console.log(response);
        } catch (err) {
            setError('Failed to add contractor. Please try again.');
            console.error(err);
        }
    };

    return (
        <Container maxWidth="sm">
            <Typography variant="h5" gutterBottom>
                Add Contractor
            </Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    label="Имя пользователя"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                />
                <TextField
                    label="Имя"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                />
                <TextField
                    label="Фамилия"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                />
                <TextField
                    label="Пароль контрактора"
                    type="password"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                <TextField
                    label="Почта"
                    type="email"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                {error && <Typography color="error">{error}</Typography>}
                {success && <Typography color="success">{success}</Typography>}
                <Button type="submit" variant="contained" color="primary" fullWidth>
                    Add Contractor
                </Button>
            </form>
        </Container>
    );
};

export default AddContractorForm;