import { apiAddress } from "constants/apiAddress";
import { LocalStorageKeys } from 'constants/localStorageKeys';



export const getAllContractors = async () =>
    await fetch(`${apiAddress}/admins/contractors_management`, {
        method: "GET",
        headers:{
            "Authorization":`Bearer ${localStorage.getItem(LocalStorageKeys.JWT_TOKEN)}`
            }})
        .then((response) => response.json())
        .catch((reason) => console.error(reason));


export const addContractor = async (username: string, first_name: string, last_name: string, password: string, email: string) => {

    const headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem(LocalStorageKeys.JWT_TOKEN)}`,
        'Content-Type': 'application/x-www-form-urlencoded',
    };

    const body = new URLSearchParams({
        username,
        firstname: first_name,
        lastname: last_name,
        password,
        email,
    }).toString();

    try {
        const response = await fetch(`${apiAddress}/admins/contractors_management`, {
            method: 'POST',
            headers: headers,
            body: body,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data; // Return the response data as needed
    } catch (error) {
        console.error('Error adding contractor:', error);
        throw error; // Rethrow the error for further handling
    }
};