import React, { useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import { Box, Button, CircularProgress, Alert, Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download'; // Import Download icon

import { downloadExportValues } from 'api/retrieve_values';

interface ExportDeviceDataProps {
  device_id: string;
}

export const ExportDeviceData: React.FC<ExportDeviceDataProps> = ({ device_id }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const currentDate = new Date();
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 365);

  const [startDate, setStartDate] = useState<Date | null>(sevenDaysAgo);
  const [endDate, setEndDate] = useState<Date | null>(currentDate);

  const handleExport = async () => {
    setLoading(true);
    setError(null);
    try {
      await downloadExportValues(device_id, startDate!, endDate!);
    } catch (error) {
      setError('Failed to download data.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box sx={{ flexDirection: 'row' }} marginBottom="1rem" display="flex" alignItems="center" p={2}>
        <DatePicker
          label="Начальная дата"
          value={startDate}
          onChange={(newValue) => setStartDate(newValue)}
          sx={{ marginRight: '1rem' }}
        />
        <DatePicker
          label="Конечная дата"
          value={endDate}
          onChange={(newValue) => setEndDate(newValue)}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleExport}
          startIcon={<DownloadIcon />}
          disabled={loading}
          sx={{ marginLeft: '1rem' }}
        >
          Экспорт
        </Button>
        {loading && (
          <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '1rem' }}>
            <CircularProgress size={24} />
            <Typography variant="body2" sx={{ marginLeft: '0.5rem' }}>
              Подготовка значений... Не закрывайте страницу
            </Typography>
          </Box>
        )}
      </Box>

      {error && <Alert severity="error">{error}</Alert>}
    </>
  );
};