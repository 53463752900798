import React, { createContext, useState, PropsWithChildren, useContext } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useEffect } from 'react';
import { CssBaseline } from '@mui/material';


// Create a provider component that will wrap your app
export const MUIThemeContext: React.FC<PropsWithChildren> = ({ children }) => {
    const shopTheme = createTheme({
      typography: {
        fontFamily: '"Ubuntu", "Segoe UI", "Roboto", "Oxygen", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
      },
        palette: {
            mode: 'light',
            primary: {
              main: '#5C6BC0',
            },
            secondary: {
              main: '#00796b',
            },
            background: {
              default: '#eceff1',
            },
          }
      });
  // Provide the context value and MUI theme to the children components
  return (
      <ThemeProvider theme={shopTheme}><CssBaseline />{children}</ThemeProvider>
  );
};
