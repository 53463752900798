import React, { useState } from 'react';
import { Button, TextField, Box, Typography, IconButton } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import { addNewDeviceFamily } from 'api/device_family';

interface EntrySchema {
  point_name: string;
  shown_name: string
  measured_values: string;
  description: string;
}

interface DeviceFamily {
  device_name: string;
  data_schema: EntrySchema[];
  description: string;
}

interface AddDeviceFamilyFormProps {
  onSuccess: () => void;
}

const DeviceFamilyForm: React.FC<AddDeviceFamilyFormProps> = ({onSuccess}) => {
  const [deviceName, setDeviceName] = useState<string>('');
  const [deviceDescription, setDeviceDescription] = useState<string>('');
  const [entries, setEntries] = useState<EntrySchema[]>([
    { point_name: '', measured_values: '', description: '', shown_name: '' },
  ]);

  const handleAddEntry = () => {
    setEntries([...entries, { point_name: '', measured_values: '', description: '', shown_name: '' }]);
  };

  const handleRemoveEntry = (index: number) => {
    const newEntries = entries.filter((_, i) => i !== index);
    setEntries(newEntries);
  };

  const handleChangeEntry = (index: number, field: keyof EntrySchema, value: string) => {
    const newEntries = [...entries];
    newEntries[index][field] = value;
    setEntries(newEntries);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const deviceFamily: DeviceFamily = {
      device_name: deviceName,
      description: deviceDescription,
      data_schema: entries,
    };
    const result = await addNewDeviceFamily(deviceFamily);
    if (result) {
      await onSuccess()
      console.log('Device Family added successfully');
    } else {
      console.log('Failed to add Device Family');
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Добавить новое семейство устройств
      </Typography>
      <TextField
        label="Имя семейства"
        fullWidth
        value={deviceName}
        onChange={(e) => setDeviceName(e.target.value)}
        required
        sx={{ mb: 2 }}
      />
      <TextField
        label="Описание"
        fullWidth
        value={deviceDescription}
        onChange={(e) => setDeviceDescription(e.target.value)}
        required
        sx={{ mb: 2 }}
      />
      {entries.map((entry, index) => (
        <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <TextField
            label="Программное Имя (Латынь)"
            value={entry.point_name}
            onChange={(e) => handleChangeEntry(index, 'point_name', e.target.value)}
            required
            sx={{ flex:1,  mr: 2 }}
          />
          <TextField
            label="Отображаемое Имя"
            value={entry.shown_name}
            onChange={(e) => handleChangeEntry(index, 'shown_name', e.target.value)}
            required
            sx={{ flex:1,  mr: 2 }}
          />
          <TextField
            label="Измеряемые значения (Латынь)"
            value={entry.measured_values}
            onChange={(e) => handleChangeEntry(index, 'measured_values', e.target.value)}
            required
            sx={{ flex:1, mr: 2 }}
          />
          <TextField
            label="Описание"
            value={entry.description}
            onChange={(e) => handleChangeEntry(index, 'description', e.target.value)}
            required
            sx={{ flex:4, mr: 2}}
          />
          <IconButton onClick={() => handleRemoveEntry(index)} color="error">
            <Remove />
          </IconButton>
        </Box>
      ))}
      {/* Button container */}
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', mt: 2, marginBottom: 3 }}>
        <Button variant="contained" color="primary" onClick={handleAddEntry} startIcon={<Add />}>
          Добавить значение
        </Button>
        <Button type="submit" variant="contained" color="secondary" sx={{ mt: 2 }}>
          Добавить семейство
        </Button>
      </Box>
    </Box>
  );
};

export default DeviceFamilyForm;