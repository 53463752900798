import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AppBar, Tab, Tabs, Typography, IconButton } from '@mui/material';
import { Routes } from 'router';
import { ReactComponent as Logo } from 'media/byonsides_logo.svg';
import { useAuth } from 'context/AuthContext';
import LogoutIcon from '@mui/icons-material/Logout'; // Import the logout icon

const Header: React.FC = () => {
  const location = useLocation();
  const { user, logout } = useAuth(); // Access user state and logout method
  const [scrollY, setScrollY] = useState(0);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  const currentPath = location.pathname;

  const tabStyles = {
    color: '#CCCCCC',
    fontWeight: 600,
  };

  const activeTabStyles = {
    ...tabStyles,
    color: '#FFFFFF',
  };

  const tabsStyles = {
    boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.1)',
  };

  const logoStyles = {
    fill: '#FFFFFF',
    height: '40px',
    width: '40px',
    marginRight: '4px',
    padding: '4px',
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const scrollDifference = currentScrollY - lastScrollY;

      setScrollY(prev => Math.min(Math.max(prev + scrollDifference, 0), 84));

      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  const handleMouseEnter = () => {
    setIsHovered(true);
    setScrollY(0);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <>
      <AppBar
        position="fixed"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          transition: 'transform 0.3s ease',
          transform: `translateY(-${isHovered ? 0 : scrollY}px)`,
          zIndex: 1000,
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', padding: '4px 8px', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Logo style={logoStyles} />
            <Typography variant="h5" style={{ fontFamily: 'Urbanist' }}>
              byonsides <b>Aqua</b>
            </Typography>
          </div>
          {user && (
            <IconButton onClick={logout} color="inherit">
              <LogoutIcon />
            </IconButton>
          )}
        </div>
        {user && (
          <Tabs
            value={currentPath}
            variant="fullWidth"
            TabIndicatorProps={{ style: { backgroundColor: '#FFFFFF' } }}
            style={tabsStyles}
          >
            <Tab
              label="Управление устройствами"
              value={Routes.DevicesListManagement}
              component={Link}
              to={Routes.DevicesListManagement}
              style={currentPath === Routes.DevicesListManagement ? activeTabStyles : tabStyles}
            />
            <Tab
              label="Семейства устройств"
              value={Routes.DevicesFamiliesManagement}
              component={Link}
              to={Routes.DevicesFamiliesManagement}
              style={currentPath === Routes.DevicesFamiliesManagement ? activeTabStyles : tabStyles}
            />
            <Tab
              label="Контракторы"
              value={Routes.Contractors}
              component={Link}
              to={Routes.Contractors}
              style={currentPath === Routes.Contractors ? activeTabStyles : tabStyles}
            />
          </Tabs>
        )}
      </AppBar>
      <div style={{ paddingTop: '100px' }}>
        {/* Main content goes here */}
      </div>
    </>
  );
};

export default Header;