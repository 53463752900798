import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import { getAllContractors } from 'api/contractors_management';
import AddContractorForm from './AddContractorForm';
import { Box } from '@mui/material';
import { Paper }  from '@mui/material';
import { Typography } from '@mui/material';

interface ContractorData {
  id: number;
  username: string;
  first_name: string;
  second_name: string;
  email: string;
  is_active: boolean;
  registration_date: Date;
}

const ContractorsList: React.FC = () => {
  const [data, setData] = useState<ContractorData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteVideoId, setDeleteVideoId] = useState('');
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
  const [isAddDeviceDialogOpen, setIsAddDeviceDialogOpen] = useState(false);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await getAllContractors();
    //   const updatedData = response.map((device: object, index: number) => ({
    //     ...device,
    //     id: index + 1,
    //   }));
      setData(response);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching video metadata:', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDelete = async (device_id: string) => {
    const confirmed = window.confirm(`Are you sure you want to delete ${device_id}?`);
    if (confirmed) {
      setIsLoading(true);
      setDeleteVideoId(device_id);
      setIsDeleteConfirmationOpen(true);
      try {
        // Perform delete operation
        // const result = await deleteDevice(device_id);
        fetchData();
      } catch (error) {
        console.error('Error deleting device:', error);
      } finally {
        setIsLoading(false);
        setIsDeleteConfirmationOpen(false);
      }
    }
  };

  const renderDeleteButton = (params: GridRenderCellParams) => (
    <Button
      variant="contained"
      color="error"
      startIcon={<DeleteIcon />}
      onClick={() => handleDelete(params.row.id as string)}
      disabled={isLoading}
    >
      Удалить
    </Button>
  );

  const renderStatusIcon = (params: GridRenderCellParams) => {
    const status = params.row.is_active as boolean;
    if (status === true) {
      return <CheckCircleIcon style={{ marginTop: '15px', color: 'green' }} />;
    }
    else{
        return <ErrorIcon style={{ marginTop: '15px', color:"red" }} />
    }
  };


  const openAddDeviceDialog = () => {
    setIsAddDeviceDialogOpen(true);
  };

  const closeAddDeviceDialog = () => {
    setIsAddDeviceDialogOpen(false);
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 50 },
    { field: 'username', headerName: 'Имя пользователя', width: 120 },
    { field: 'first_name', headerName: 'Имя', width: 120 },
    { field: 'last_name', headerName: 'Фамилия', width: 230 },
    { field: 'email', headerName: 'Почта', width: 230 },
    // { field: 'is_active', headerName: 'Активно', width: 200 },
    { field: 'registration_time', headerName: 'Время регистрации', width: 120 },
    {
      field: 'status_icon',
      headerName: 'Активен',
      width: 100,
      renderCell: renderStatusIcon,
    },
    // {
    //   field: 'delete',
    //   headerName: 'Удалить',
    //   width: 140,
    //   renderCell: renderDeleteButton,
    // }
  ];

  return (
    
    <Box style={{ height: 800, width: '100%' }}>
      <Paper sx={{ padding: 3, display: 'flex', alignItems: 'center', marginBottom: 3 }} elevation={5}>
      <Button variant="contained" color="primary" onClick={openAddDeviceDialog}>
         Зарегистрировать контрактора
      </Button>
      </Paper>
    <Paper sx={{ padding: 3, marginBottom: 3 }} elevation={5}>
      <Typography variant='h4' sx={{marginBottom: 3}}>Список контракторов</Typography>
    <DataGrid rows={data} columns={columns} initialState={{
        sorting: {
        sortModel: [{ field: 'registration_time', sort: 'desc' }],
        },
      }}/>
      </Paper>


    <Dialog open={isAddDeviceDialogOpen} onClose={closeAddDeviceDialog}>
        <DialogContent>
          <AddContractorForm onSuccess={fetchData} />
        </DialogContent>
      </Dialog>

      <Dialog open={isDeleteConfirmationOpen} onClose={() => setIsDeleteConfirmationOpen(false)}>
        <DialogContent>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <CircularProgress size={20} style={{ marginRight: '10px' }} />
            <span>Удаление устройства {deleteVideoId}...</span>
          </div>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default ContractorsList;