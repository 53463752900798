import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { getAllDeviceFamilies } from 'api/device_family';
import DeviceFamilyForm from './AddDeviceFamilyForm';
import ModifyDeviceFamilyForm from './ModifyDeviceFamilyForm';
import { Box, Paper } from '@mui/material';
import { DialogTitle } from '@mui/material';

interface VideoData {
  video_id: string;
  uploaded_at: string;
  indexed_at: string;
  status: string;
  frames_count: number;
  video_time: string;
  framerate: number;
}

const DevicesFamilies: React.FC = () => {
  const [data, setData] = useState<VideoData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteVideoId, setDeleteVideoId] = useState('');
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
  const [isAddDeviceDialogOpen, setIsAddDeviceDialogOpen] = useState(false);
  const [isEditDeviceDialogOpen, setIsEditDeviceDialogOpen] = useState(false);
  const [selectedDeviceFamilyId, setSelectedDeviceFamilyId] = useState<number | null>(null);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await getAllDeviceFamilies();
      setData(response);
    } catch (error) {
      console.error('Error fetching device families:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEdit = (deviceFamilyId: number) => {
    setSelectedDeviceFamilyId(deviceFamilyId);
    setIsEditDeviceDialogOpen(true);
  };

  const renderEditButton = (params: GridRenderCellParams) => (
    <Button
      variant="contained"
      color="secondary"
      onClick={() => handleEdit(params.row.id as number)}
      disabled={isLoading}
    >
      Изменить
    </Button>
  );

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID семейства', width: 120 },
    { field: 'device_name', headerName: 'Имя семейства', width: 250 },
    { field: 'adding_time', headerName: 'Дата добавления', width: 250 },
    { field: 'description', headerName: 'Описание', width: 300 },
    {
      field: 'status_icon',
      headerName: 'Активно',
      width: 100,
      renderCell: (params) => renderStatusIcon(params),
    },
    {
      field: 'edit',
      headerName: '',
      width: 140,
      renderCell: renderEditButton,
    },
  ];

  const renderStatusIcon = (params: GridRenderCellParams) => {
    const status = params.row.is_active as boolean;
    return status ? (
      <CheckCircleIcon style={{ marginTop: '15px', color: 'green' }} />
    ) : (
      <ErrorIcon style={{ marginTop: '15px', color: 'red' }} />
    );
  };

  const openAddDeviceDialog = () => {
    setIsAddDeviceDialogOpen(true);
  };

  const closeAddDeviceDialog = () => {
    setIsAddDeviceDialogOpen(false);
  };

  const closeEditDeviceDialog = () => {
    setIsEditDeviceDialogOpen(false);
    setSelectedDeviceFamilyId(null);
  };

  return (
    <Box style={{ height: 800, width: '100%' }}>
      <Paper sx={{ padding: 3, display: 'flex', alignItems: 'center', marginBottom: 3 }} elevation={5}>
        <Button variant="contained" color="primary" onClick={openAddDeviceDialog}>
          Добавить семейство устройств
        </Button>
      </Paper>
      <Paper sx={{ padding: 3, display: 'flex', alignItems: 'center', marginBottom: 3 }} elevation={5}>
        <DataGrid rows={data} columns={columns} initialState={{
          sorting: {
            sortModel: [{ field: 'uploaded_at', sort: 'desc' }],
          },
        }} />
      </Paper>

      <Dialog fullWidth maxWidth="xl" open={isAddDeviceDialogOpen} onClose={closeAddDeviceDialog}>
      <DialogTitle>Добавить семейство устройств</DialogTitle>
        <DialogContent>
          <DeviceFamilyForm onSuccess={fetchData} />
        </DialogContent>
      </Dialog>

      <Dialog fullWidth maxWidth="xl" open={isEditDeviceDialogOpen} onClose={closeEditDeviceDialog}>
      <DialogTitle>Изменить семейство устройств</DialogTitle>
        <DialogContent>
          {selectedDeviceFamilyId && (
            <ModifyDeviceFamilyForm device_family_id={selectedDeviceFamilyId} onSuccess={fetchData} />
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default DevicesFamilies;