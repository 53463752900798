import { apiAddress } from "constants/apiAddress";
import { LocalStorageKeys } from 'constants/localStorageKeys';


export const getDeviceValues = async (device_id: string, start_date: Date, end_date: Date) =>
    await fetch(`${apiAddress}/admins/data/values/${device_id}/aggregate?start_date=${start_date.toISOString().split("T")[0]}&end_date=${end_date.toISOString().split("T")[0]}`, {
        method: "GET",
        headers:{
            "Authorization":`Bearer ${localStorage.getItem(LocalStorageKeys.JWT_TOKEN)}`,
            "Content-Type": "application/json"
            }})
        .then((response) => response.json())
        .catch((reason) => console.error(reason));


export const downloadExportValues = async (device_id: string, start_date: Date, end_date: Date) => {
    try {
        const response = await fetch(`${apiAddress}/admins/data/export/${device_id}?start_date=${start_date.toISOString().split("T")[0]}&end_date=${end_date.toISOString().split("T")[0]}`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${localStorage.getItem(LocalStorageKeys.JWT_TOKEN)}`,
                "Content-Type": "application/json"
            }
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `export_${device_id}_${start_date.toISOString().split("T")[0]}_${end_date.toISOString().split("T")[0]}.csv`; // Adjust file name and extension as needed
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.error('Download error:', error);
    }
};