import React, { useState, useEffect } from 'react';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { getAllDeviceFamilies } from 'api/device_family';
import { addNewDevice } from 'api/devices';

interface DeviceFamily {
  id: number;
  device_name: string;
  is_active: boolean;
  adding_time: string;
  data_schema: {
    point_name: string;
    measured_values: string;
    description: string;
  }[];
  description: string;
}

interface FormValues {
  deviceFamilyId: number;
  description: string;
}


interface AddDeviceFormProps {
  onSuccess: () => void;
}

const AddDeviceForm: React.FC<AddDeviceFormProps> = ({onSuccess}) => {
  const [deviceFamilyId, setDeviceFamilyId] = useState<number | null>(null);
  const [description, setDescription] = useState<string>('');
  const [deviceFamilies, setDeviceFamilies] = useState<DeviceFamily[]>([]);

  useEffect(() => {
    const fetchDeviceFamilies = async () => {
      try {
        const families: DeviceFamily[] = await getAllDeviceFamilies();
        setDeviceFamilies(families);
      } catch (error) {
        console.error('Error fetching device families:', error);
      }
    };

    fetchDeviceFamilies();
  }, []);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (deviceFamilyId) {
      // Call your API function here with formValues
      await addNewDevice(deviceFamilyId, description)
      await onSuccess()
      // Reset form fields
      setDeviceFamilyId(null);
      setDescription('');
    }

  };

  return (
    <form onSubmit={handleSubmit}>
      <FormControl fullWidth sx={{ marginBottom: '1rem' }}>
        <InputLabel>Семейство</InputLabel>
        <Select
          labelId="device-family-label"
          id="device-family"
          value={deviceFamilyId}
          onChange={(event) => setDeviceFamilyId(event.target.value as number)}
          required
        >
          {deviceFamilies.map((deviceFamily) => (
            <MenuItem key={deviceFamily.id} value={deviceFamily.id}>
              {deviceFamily.device_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        id="description"
        label="Описание"
        fullWidth
        value={description}
        onChange={(event) => setDescription(event.target.value)}
        required
        sx={{ marginBottom: '1rem' }}
      />

      <Button type="submit" variant="contained" color="primary">
        Добавить устройство
      </Button>
    </form>
  );
};

export default AddDeviceForm;