import { Box } from '@mui/system';
import ContractorsList from 'components/ContractorsList';

export const Contractors = () => {
  return (
    <Box sx={{ margin: '2rem' }}>
      <h1>Контракторы</h1>
      <ContractorsList />
    </Box>
  );
};