import * as React from 'react';
import { useState } from 'react';
import Plot from "react-plotly.js";
import { Data } from 'plotly.js';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import InputLabel from '@mui/material/InputLabel';

interface PlotData {
  measured_value: string;
  timestamps: string[][];
  mean: number[][];
  median: number[][];
  stddev: number[][];
  q1: number[][];
  q3: number[][];
  max: number[][];
  min: number[][];
  point_names: string[];
  shown_names: string[];
}

interface PlotDataProps {
  plot_data: PlotData;
}


export const BoxPlot: React.FC<PlotDataProps> = ({ plot_data }) => {

    const data_for_plot: Data[] = [];

    for (var i = 0; i < plot_data.point_names.length; i++) {
        const timestamps = [];
        for (const item of plot_data.timestamps[i]) {
          const timestamp = new Date(item);
          timestamps.push(timestamp);
        }
    
        data_for_plot.push({
          x: timestamps,
        //   @ts-ignore
          median: plot_data.median[i],
          stddev: plot_data.stddev[i],
          q1: plot_data.q1[i],
          q3: plot_data.q3[i],
          upperfence: plot_data.max[i],
          lowerfence: plot_data.min[i],
          name: plot_data.shown_names[i],
          type: "box"
        });
      }

    const colors = [
      '#5C6BC0', '#00796b', '#2ca02c', '#d62728', '#9467bd', '#8c564b', '#e377c2', '#7f7f7f', '#bcbd22', '#17becf'
    ]

    return (   
        <>
        <Box sx={{marginBottom: 4, padding: 2, border: '1px solid #ddd', borderRadius: 2, backgroundColor: '#fafafa'}}>
        <Box display="flex" alignItems="center" pb={2} mb={2} borderBottom="1px solid #ddd">
        <BubbleChartIcon color="primary" />
            <Typography variant="h6" component="h2" ml={2} fontWeight="bold">
                  {plot_data.measured_value}
            </Typography>
        </Box>
        <Plot
        data={data_for_plot}
        useResizeHandler={true}
        style={{ width: "100%", height: 800 }}
        layout={{
          xaxis: {
            title: "Время",
          },
          yaxis: {
            title: plot_data.measured_value,
          },
          boxmode: 'group',
          colorway: colors
        }}
      />
      </Box>
    </>
    );
}