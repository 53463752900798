import { Home } from 'pages/Home';
import { DevicesListManagement } from 'pages/DevicesListManagement';
import { FC } from 'react';
import { BrowserRouter, Navigate, Route, Routes as RoutesComponent } from 'react-router-dom';
import { ScrollToTop } from 'router/ScrollToTop';
import Header from 'components/Header';
import { DevicesFamiliesManagement } from 'pages/DevicesFamiliesManagement';
import { DevicePage } from 'pages/DevicePage';
import { MUIThemeContext } from 'context/MUIThemeContext';
import { Login } from 'pages/Login';
import { AuthProvider } from 'context/AuthContext';
import { RequireAuth } from './RequireAuth';
import { Contractors } from 'pages/Contractors';

export enum Routes {
  Home = '/',
  DevicesListManagement = '/devices-list',
  DevicesFamiliesManagement = '/devices-families',
  DevicePage = '/device-data',
  Login = "/login",
  Contractors = "/contractors"
}

export const Router: FC = () => {
  return (
    <AuthProvider>
      <BrowserRouter>
        <MUIThemeContext>
          <ScrollToTop />
          <Header />
          <RoutesComponent>
            <Route path={Routes.Home} element={<RequireAuth component={<Home />} />} />
            <Route path={Routes.DevicesListManagement} element={<RequireAuth component={<DevicesListManagement />} />} />
            <Route path={Routes.DevicesFamiliesManagement} element={<RequireAuth component={<DevicesFamiliesManagement />} />} />
            <Route path={Routes.DevicePage} element={<RequireAuth component={<DevicePage />} />} />
            <Route path={Routes.Contractors} element={<RequireAuth component={<Contractors />} />} /> 
            <Route path={Routes.Login} element={<Login />} />
            <Route path="*" element={<Navigate to={Routes.Home} replace />} />
          </RoutesComponent>
        </MUIThemeContext>
      </BrowserRouter>
    </AuthProvider>
  );
};