import React, { useEffect, useState } from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import Plot from "react-plotly.js";
import { Data } from 'plotly.js';
import { getDeviceValues } from 'api/retrieve_values';
import { MetricsPlot } from './DataPlotPlotly';
import { Divider } from "@mui/material";
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { getDeviceHourlyMetrics } from 'api/retrieve_metrics';
import { BoxPlot } from './BoxPlot';
import Alert from '@mui/material/Alert'; // Import Alert component

interface MetricsData {
    measured_value: string;
    timestamps: string[][];
    mean: number[][];
    median: number[][];
    stddev: number[][];
    q1: number[][];
    q3: number[][];
    max: number[][];
    min: number[][];
    point_names: string[];
    shown_names: string[];
}

interface DeviceAnalyticsProps {
  device_id: string;
}

export const DeviceAnalytics: React.FC<DeviceAnalyticsProps> = ({ device_id }) => {
  const [hourlyData, setHourlyData] = useState<MetricsData[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null); // Error state

  const currentDate = new Date();
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 3);

  const [startDate, setStartDate] = useState<Date | null>(sevenDaysAgo);
  const [endDate, setEndDate] = useState<Date | null>(currentDate);

  const fetchMetrics = async () => {
    setLoading(true);
    setError(null); // Reset error state
    try {
      if (startDate && endDate) {
        const data = await getDeviceHourlyMetrics(device_id, startDate, endDate);
        setHourlyData(data);
      }
    } catch (error) {
      console.error('Error fetching device', error);
      setError('Ошибка при загрузке данных. Пожалуйста, попробуйте позже.'); // Set error state
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    const fetchAllData = async () => {
      await fetchMetrics();
    };

    fetchAllData();
    const interval = setInterval(fetchAllData, 1000000);

    return () => {
      clearInterval(interval);
    };
  }, [startDate, endDate]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Box sx={{ flexDirection: 'row' }} marginBottom="1rem" display="flex" alignItems="center">
        <DatePicker
          label="Начальная дата"
          value={startDate}
          onChange={(newValue) => setStartDate(newValue)}
          sx={{ marginRight: '1rem' }}
        />
        <DatePicker
          label="Конечная дата"
          value={endDate}
          onChange={(newValue) => setEndDate(newValue)}
        />
        {loading && <CircularProgress size={24} sx={{ marginLeft: '1rem' }} />}
      </Box>
      
      {error && <Alert severity="error">{error}</Alert>} {/* Show error alert */}


      {hourlyData && hourlyData.length === 0 ? (
        <Alert severity="info">Данные за выбранный период отсутствуют</Alert>
      ) : (
        hourlyData && hourlyData.map((plotData, index) => (
          <div key={index}>
            <BoxPlot plot_data={plotData} />
          </div>
        ))
      )}
    </>
  );
}