import { Box } from '@mui/system';
import DevicesList from 'components/DevicesList';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { apiAddress } from 'constants/apiAddress';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AddDeviceForm from 'components/AddDeviceForm';


export const DevicesListManagement = () => {
  return (
    <Box sx={{ margin: '2rem' }}>
      <h1>Управление устройствами</h1>
      <DevicesList />
    </Box>
  );
};