import LoginForm from "components/LoginForm";
import { handleLogin, getUserData } from "api/login";
import { useAuth, User } from "context/AuthContext";
import { Paper, Box } from '@mui/material';

export const Login = () => {
  const { login } = useAuth();

  const handleFormSubmit: (username: string, password: string) => Promise<boolean> = async (username, password) => {
    // console.log(formData);
    // const { username, password } = formData; // Extract username and password from formData
    const token = await handleLogin(username, password); // Pass them separately to handleLogin
    console.log(token)
    if (token) {
      const user_data = await getUserData(token);
      const user: User = {
        id: user_data.id,
        email: user_data.email,
        last_name: user_data.last_name,
        first_name: user_data.first_name,
        username: user_data.username,
      };
      login(user, token);
      return true;
    } else {
      return false;
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      bgcolor="#f0f0f0"
    >
      <Paper elevation={3} sx={{
          padding: { xs: '16px', sm: '20px' }, // Responsive padding
          width: { xs: '90%', sm: '600px' }, // Full width on mobile, fixed width on larger screens
          borderRadius: '8px',
        }}>
        <LoginForm onSubmit={handleFormSubmit} />
      </Paper>
    </Box>
  );
};